export const getDomain = () => {
    if (location.origin.includes('.ca')) {
        return 'dietid.ca';
    } else {
        return 'dietid.com';
    }
};

export const ENVIRONMENT = [
    ['production', location.origin.includes('capacitor://localhost')],
    ['development', !location.origin.includes('capacitor://localhost') && !location.origin.includes(getDomain())],
    ['staging', location.origin.includes(`staging.${getDomain()}`)],
    ['production', !location.origin.includes(`staging.${getDomain()}`) && location.origin.includes(getDomain())]
].find(([name, isActive]) => isActive)[0];

export const getClientHost = (partnerToken) => ({
    development: `http://${partnerToken}.localhost:19006`,
    staging: `https://${partnerToken}.staging.${getDomain()}`,
    production: `https://${partnerToken}.${getDomain()}`
}[ENVIRONMENT]);

export const getClientBetaHost = (partnerToken) => ({
    development: `http://${partnerToken}.beta.localhost:19006`,
    staging: `https://${partnerToken}.beta.staging.${getDomain()}`,
    production: `https://${partnerToken}.beta.${getDomain()}`
}[ENVIRONMENT]);

export const API_HOST = {
    development: 'http://localhost:5001/api/v1',
    staging: `https://api-staging.${getDomain()}/api/v1`,
    production: `https://api-prod.${getDomain()}/api/v1`
}[ENVIRONMENT];

export const API_HOST_V3 = {
    development: 'http://localhost:5001/api/v3',
    staging: `https://api-staging.${getDomain()}/api/v3`,
    production: `https://api-prod.${getDomain()}/api/v3`
}[ENVIRONMENT];


export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-2',
  production: 'UA-137832774-3'
}[ENVIRONMENT]

const asAdmin = location.search.includes('role=admin')

export const LOCAL_STORAGE_AUTH_KEY = asAdmin ? 'DQPN_ADMIN_AUTH' : 'DQPN_AUTH'
export const LOCAL_STORAGE_PARTNER_TOKEN_KEY = asAdmin ? 'DQPN_ADMIN_PARTNER_TOKEN' : 'DQPN_PARTNER_TOKEN'
export const LOCAL_STORAGE_VIEW_KEY = 'DIET_ID_VIEW'
export const LOCAL_STORAGE_LOCALE_KEY = 'wglang' || 'DIET_ID_LOCALE'
export const LOCAL_STORAGE_DIET_ID_REDIRECT = 'DIET_ID_REDIRECT'
